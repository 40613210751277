import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // Import Switch from react-router-dom
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Search from './Search';
import RandomRecord from './RandomRecord';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RandomRecord />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </Router>
  );
};

// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route exact path="/">
//           <Search />
//         </Route>
//         <Route path="/random">
//           <RandomRecord />
//         </Route>
//       </Routes>
//     </Router>
//   );
// };

export default App;

// function App() {
//   return (
//     <div className="App">
//       <h1>Algolia Search Example</h1>
//       <Search />
//     </div>
//   );
// }

// export default App;

// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
