import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';

const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleSearch = async () => {
    const client = algoliasearch(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_API_KEY);
    const index = client.initIndex(process.env.REACT_APP_INDEX_NAME);
    const { hits } = await index.search(query);
    setResults(hits);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown} // Listen for Enter key press
      />
      <button onClick={handleSearch}>Send</button>
      <ul>
        {results.map((hit) => (
          <li key={hit.objectID}>{hit.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default Search;


// import React, { useState } from 'react';
// import algoliasearch from 'algoliasearch/lite';

// const Search = () => {
//   const [query, setQuery] = useState('');
//   const [results, setResults] = useState([]);
//   // const applicationId = process.env.REACT_APP_APPLICATION_ID;
//   // const apiKey = process.env.REACT_APP_API_KEY;
//   // const indexName = process.env.REACT_APP_INDEX_NAME;
//   // console.log(process.env.REACT_APP_APPLICATION_ID);


//   const handleSearch = async (query) => {
//     // const client = algoliasearch(applicationId, apiKey);
//     // const index = client.initIndex(indexName);
//     // console.log(process.env.REACT_APP_APPLICATION_ID);
//     const client = algoliasearch(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_API_KEY);
//     const index = client.initIndex(process.env.REACT_APP_INDEX_NAME);
//     const { hits } = await index.search(query);
//     setResults(hits);
//   };

//   return (
//     <div>
//       <input
//         type="text"
//         value={query}
//         onChange={(e) => {
//           setQuery(e.target.value);
//           handleSearch(e.target.value);
//         }}
//       />
//       <ul>
//         {results.map((hit) => (
//           <li key={hit.objectID}>{hit.title}</li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Search;
