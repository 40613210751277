// RandomRecord.js
import React, { useState, useEffect } from 'react';
// import algoliasearch from 'algoliasearch/lite';
import algoliasearch from 'algoliasearch';
import './RandomRecord.css'; // Import CSS file for styling

const RandomRecord = () => {
  const [randomRecord, setRandomRecord] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchTotalRecords = async () => {
      const client = algoliasearch(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_API_KEY);
      const index = client.initIndex(process.env.REACT_APP_INDEX_NAME);
      const { nbHits } = await index.search('');
      setTotalRecords(nbHits); // Set the total number of records in the index
    };

    fetchTotalRecords();
  }, []); // Run once on component mount

  const getRandomIndex = () => {
    const index = Math.floor(Math.random() * totalRecords);
    return index;
  };

  const handleFetchRandomRecord = async () => {
    const client = algoliasearch(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_API_KEY);
    const index = client.initIndex(process.env.REACT_APP_INDEX_NAME);
    const { hits } = await index.search('', {
      hitsPerPage: 1,
      page: getRandomIndex(),
    });
    setRandomRecord(hits[0]); // Set the random record
  };

  const handleDeleteRecord = async () => {
    if (!randomRecord) return;

    const client = algoliasearch(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_API_KEY);
    const index = client.initIndex(process.env.REACT_APP_INDEX_NAME);
    console.log('id',randomRecord.objectID);
    await index.deleteObject(randomRecord.objectID);
    setRandomRecord(null); // Clear the deleted record from the component state
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleFetchRandomRecord();
    }
  };

  const cleanTitle = randomRecord ? randomRecord.Title.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-') : '';

  const handleWatchClick = () => {
    // Redirect to JustWatch URL when the button is clicked
    window.open(`https://www.justwatch.com/eg/movie/${encodeURIComponent(cleanTitle)}`, '_blank');
  };

  const handleURLClick = () => {
    // Redirect to the URL when the button is clicked
    window.open(randomRecord.URL, '_blank');
  };

  return (
    <div className="random-record-container">
      <button className="fetch-button" onClick={handleFetchRandomRecord} onKeyPress={handleKeyPress}>Fetch Random Movie</button>
      {randomRecord && (
        <div className="record-details">
          <h3>Title: {randomRecord.Title}</h3>
          <button className="imdb-url-button" onClick={handleURLClick}>URL</button>
          <div className="button-space"></div>
          <button className="watch-button" onClick={handleWatchClick}>Where To Watch</button>
          <p>IMDb Rating: {randomRecord['IMDb Rating']}</p>
          {randomRecord['Poster Url'] && <img src={randomRecord['Poster Url']} alt="Poster" className="poster-image" />}
          <button className="delete-button" onClick={handleDeleteRecord}>Delete Record</button>
        </div>
      )}
    </div>
  );
};

export default RandomRecord;
